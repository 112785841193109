import React from "react";
import {Modal} from "antd";

export default {
    confirm(title, content, onOk) {
        Modal.confirm({
            title,
            content,
            onOk
        });
    }
}