import qs from 'qs'
import React from 'react'
import { Message } from '@biz/Components'
import moment from 'moment'
import axios from 'axios'
axios.defaults.timeout = 20 * 60 * 1000
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.responseType = 'json'

// 在提交之前去除首尾的空格
const trimObj = (data) => {
  if (typeof data === 'string') {
    let newData = data.trim()
    return newData.length > 0 ? newData : null
  } else if (Array.isArray(data)) {
    return data.map((item) => trimObj(item))
  } else if (Object.prototype.toString.call(data) === '[object Object]') {
    Object.keys(data).forEach((key) => {
      data[key] = trimObj(data[key])
    })
    return data
  } else {
    return data
  }
}
const root = '/car'

const dealUrl = (url) => {
  if (!url.startsWith('/pub')) {
    url = '/web' + url
  }
  return root + url
}

const dealHeader = (config) => {
  /* config.headers["userid"]=window.user.userId;
    config.headers["username"]= encodeURIComponent(window.user.userName);
    */
}
/** 后台接口返回判断是否是回话超时或者没有资源权限*/
const codes = ['REQUIRE_LOGIN']

/**
 * application/x-www-form-urlencoded
 */
const listGetAjax = axios.create()
listGetAjax.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // GET请求加时间戳参数以达到cache: false
    if (config.method === 'get') {
      config.paramsSerializer = function (params) {
        return qs.stringify(trimObj(params), { arrayFormat: 'repeat' })
      }
      config.params = { ...config.params, _: new Date().getTime() + 1 }
    }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

listGetAjax.interceptors.response.use(
  function (response) {
    // Do something with response data
    visitErr(response.data.errCode)
    return response.data
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error)
  }
)

const formUrlAjax = axios.create()
// Add a request interceptor

formUrlAjax.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // GET请求加时间戳参数以达到cache: false
    if (config.method === 'get') {
      config.params = { ...config.params, _: new Date().getTime() + 1 }
    }
    // POST请求参数序列化
    if (config.method === 'post') {
      config.data = qs.stringify(trimObj(config.data), {
        allowDots: true,
        arrayFormat: 'repeat',
      })
    }
    config.url = dealUrl(config.url)
    dealHeader(config)
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
formUrlAjax.interceptors.response.use(
  function (response) {
    // Do something with response data
    visitErr(response.data.errCode)
    return response.data
  },
  function (error) {
    // Do something with response error

    Message.error('Network err! ' + error)
    return Promise.reject(error)
  }
)

function visitErr(errCode) {
  if (codes.includes(errCode)) {
    // setTimeout(() => {
    let { pathname, search } = window.location
    if (pathname.indexOf('/page') === 0) {
      let url = pathname + search
      url = encodeURIComponent(url)
      window.location.href = '/login?redirect_url=' + url
    } else {
      window.location.href = '/login'
    }
    // }, 1500);
    return true
  }
}

/**
 * application/json
 */
const jsonAjax = axios.create()
// Add a request interceptor
jsonAjax.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // GET请求加时间戳参数以达到cache: false
    if (config.method === 'get') {
      config.params = { ...config.params, _: new Date().getTime() + 1 }
    }
    config.url = dealUrl(config.url)
    dealHeader(config)
    return trimObj(config)
  },
  function (error) {
    // Do something with request error

    Message.error('Network err! ' + error)
    return Promise.reject(error)
  }
)

// Add a response interceptor
jsonAjax.interceptors.response.use(
  function (response) {
    // Do something with response data
    visitErr(response.data.errCode)
    return response.data
  },
  function (error) {
    // Do something with response error

    Message.error('Network err! ' + error)
    return Promise.reject(error)
  }
)

/**
 * Form Data
 */
const formDataAjax = axios.create()
// Add a request interceptor
formDataAjax.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // GET请求加时间戳参数以达到cache: false
    if (config.method === 'get') {
      config.params = { ...config.params, _: new Date().getTime() + 1 }
    }
    config.url = dealUrl(config.url)
    dealHeader(config)
    return trimObj(config)
  },
  function (error) {
    // Do something with request error

    Message.error('Network err! ' + error)
    return Promise.reject(error)
  }
)

// Add a response interceptor
formDataAjax.interceptors.response.use(
  function (response) {
    // Do something with response data
    visitErr(response.data.errCode)
    return response.data
  },
  function (error) {
    // Do something with response error
    Message.error('Network err! ' + error)
    return Promise.reject(error)
  }
)

// 文件下载
const fileAjax = (params) => {
  dealHeader(params)
  axios({
    ...params,
    responseType: 'blob',
  }).then(
    (rsp) => {
      window.rsp = rsp
      if (rsp.data.type && rsp.data.type.indexOf('application/json') === 0) {
        let reader = new FileReader()
        reader.onload = function (e) {
          let res = JSON.parse(e.target.result)
          if (!res.success) {
            Message.error(res.errMessage || res.errCode)
          }
          if (params.callback) {
            params.callback(res)
          }
        }
        reader.readAsText(rsp.data)
        return
      }
      let len = rsp.headers['content-length']
      // 文件为空则不处理
      if (len == 0) {
        params.callback && params.callback()
        return
      }
      let filename = decodeURIComponent(rsp.headers['filename'])
      if (!filename || filename == 'undefined') {
        filename = params.filename || moment().format('YYYYMMDD_HHmmss')
      }
      downloadAsFile(rsp.data, filename, params.callback)
    },
    (err) => {
      if (params.errorCallback) {
        params.errorCallback()
      }
      Message.error('Network err! ' + err)
      console.log(err)
    }
  )
}
/**
 *
 * @param data blob对象
 * @param filename
 * @param callback
 */
const downloadAsFile = (data, filename, callback) => {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(data, filename)
    return
  }
  let downloadUrl = window.URL.createObjectURL(data)
  let anchor = document.createElement('a')
  document.body.appendChild(anchor)
  anchor.target = '_blank'
  anchor.href = downloadUrl
  anchor.download = filename
  anchor.click()
  if (callback && typeof callback == 'function') {
    callback()
  }
  window.URL.revokeObjectURL(data)
  document.body.removeChild(anchor)
}

const downloadAsFileByBase64 = (base64str, type, filename, callback) => {
  // base64 解码
  let data = window.atob(base64str)
  let n = data.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = data.charCodeAt(n)
  }
  data = new Blob([u8arr], { type })
  downloadAsFile(data, filename, callback)
}

const uploadPrefix = root + '/web/resource/file/'
const serializeData = (data) =>
  qs.stringify(trimObj(data), { allowDots: true, arrayFormat: 'repeat' })
export default {
  root: root,
  uploadUrl: uploadPrefix + 'upload',
  uploadShopWithdrawalUrl: uploadPrefix + 'uploadShopWithdrawal',
  uploadDocumentFile: uploadPrefix + 'uploadDocumentFile',

  Ajax: formUrlAjax,
  jsonAjax: jsonAjax,
  formDataAjax: formDataAjax,
  listGet: listGetAjax,
  fileAjax,
  trimObj,
  downloadAsFile,
  downloadAsFileByBase64,
  serializeData,
}
