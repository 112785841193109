export default {
    Scope: {
        ROOT: "管理员",
        SHOP: "门店",
        USER: "普通用户"
    },

    DetailStatus: {
        create: "新建",
        edit: "编辑",
        view: "详情"
    },

    SUPPORT:["支持", "不支持"],
    Enabled:["正常", "停用"],
    Show:["不展示", "展示"],
    VipOrder:["会员订单", "普通订单"],

    WashOrderType:{
        "1":"普通订单",
        "2":"会员订单"
    },
    ShopStationStatus:{
        1:"启动中",
        2:"工作中" ,
        3:"停止中",
        4:"已空闲"
    },

    GeekDeviceStatus:{
        "-1": "离线",
        "1": "在线"
    },
    GeekRelatedStatus:{
        "0": "未关联",
        "1": "已关联"
    },
    DocumentType:{
      "Ads":"广告",
      "Doc":"文档"
    },

    BalanceReferenceType:{
        1:"余额充值",
        2:"自助洗车"
    },
    UserBalanceEventType:{
        101: "余额充值",
        102: "余额充值赠送",
        103: "余额充值退款",
        201: "自助洗车消费",
        202: "自助洗车退款",
    },
    OnOff:["开","关"],
    LinkType:{
        1:"小程序页面",
        2:"文档",
        3:"外部H5",
        4:"富文本内容"
    },
    LinkTypeTip:{
        1:"列表取值为【系统配置】界面中[WXPage]相关的配置项，点击可跳转到小程序内部页面",
        2:"列表取值为【文档管理】界面中[文件]相关的配置项，点击可打开对应的文件，主要是doc, pdf文件",
        3:"列表取值为外部链接地址，点击打开对应的网页",
        4:"列表取值为【文档管理】界面中[富文本内容]相关的配置项，点击可展示对应的富文本内容"
    },
    AdsPosition:{
        1:"首页Banner"
    },
    RechargeOrderStatus:{
        1:"待支付",
        2:"支付成功",
        3:"已关闭",
        4:"已退款"
    },
    VipOrderStatus:{
        1:"待支付",
        2:"支付成功",
        3:"已关闭",
        4:"已退款"
    },
    WashOrderStatus:{
        1:"待支付",
        2:"支付成功",
        3:"已关闭",
        4:"已退款"
    },
    ShopWithdrawalStatus:{
        1:"未打款",
        2:"已打款"
    },
    ShopBalanceEvent:{
        201: "自助洗车冻结",
        202: "自助洗车退款",
        203: "自助洗车结算",

        301: "提现申请",
        302: "提现打款",
    },
    ShopBalanceReferenceType:{
        2: "自助洗车",
        3: "提现",
    },
    DocType:{
        1:"首页介绍文档",
        2:"富文本内容",
        5:"文件",
        10:"其他"
    },
    DocTypeTip:{
        1:"用于展示在小程序首页",
        2:"用于在小程序展示特定的内容",
        5:"文件,可在广告页配置对应文档",
        10:"其他类型"
    },
    WechatPage:{
        1:"首页",
        2:"门店页面"
    }

}