

export default {
    ...require('./ajax').default,
    ...require('./security').default,
    ...require('./jq-alike').default,
    ...require('./confirm').default,
    ...require('./url').default,
    ...require('./common').default,
    ...require('./render').default,
    ...require('./date').default,
    ...require('./preview').default
}