import Reflux from "reflux"
import GUtils from "@/utils";

const prefix = "/my/"
const api = {
    getMenus: prefix + "menus",
    logout: "/pub/logout",
}

const createAction = () => {
    const Action = Reflux.createActions({
        getMenus: {asyncResult: true},
        logout: {asyncResult: true}
    });

    Action.getMenus.listen(function () {
        GUtils.Ajax.get(api.getMenus).then(this.completed, this.failed)
    })
    Action.logout.listen(function () {
        GUtils.Ajax.post(api.logout).then(this.completed, this.failed)
    })
    return Action;
};

export {api, createAction}