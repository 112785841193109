import React from "react";

import {ArrowUpOutlined, ArrowDownOutlined} from "@ant-design/icons";
import {Tag} from "antd";
import {Link} from "react-router-dom";
import Confirm from "./confirm";
import {LdhButton} from "@biz/Components";
export default {
    renderEnabledValue(v){
        return  v ? <Tag color="success">正常</Tag> : <Tag color="error">停用</Tag>
    },

    renderDetailLink(id, className){
        return <Link to={`detail/view?id=${id}`} className={className}>查看详情</Link>
    },
    renderEditLink(id, className){
        return <Link to={`detail/edit?id=${id}`} className={className}>编辑</Link>
    },
    renderEnabledLink(vo,type, field, action){
        let {id, enabled} = vo;
        let op = enabled ? "禁用" :"启用";
        const handleClick = ()=>{

            Confirm.confirm(op,`确认${op}${type} [${vo[field]}] 吗?`,()=>{
                action.enabled(id, !enabled);
            });
        }

        return  <a onClick={handleClick} className={enabled ?"danger" : ""}>{op}</a>
    },
    renderDeleteLink(vo,type, field, action){
        let {id} = vo;
        let op = "删除";
        const handleClick = ()=>{

            Confirm.confirm(op,`确认${op}${type} [${vo[field]}] 吗?`,()=>{
                action.delete(id);
            });
        }

        return  <a onClick={handleClick} className="danger">{op}</a>
    },

    // 排序列
    initSortField(voList=[]){
        let len = voList.length;
        for(let i in voList){
            i = parseInt(i);
            console.log(i)
            if(i>0){
                console.log("===1",i);
                voList[i]._preId = voList[i-1].id;
            }
            if(i<len-1){
                console.log("===2,",i);
                voList[i]._nextId = voList[i+1].id;
            }
        }
    },
    renderSortOpCol(action, field){
        const handleUp = (vo)=>{
            if(!vo._preId){return;}
            handleSort("上移", vo, vo._preId);
        }
        const handleDown = (vo)=>{
            if(!vo._nextId){return;}
            handleSort("下移", vo ,vo._nextId);
        }

        const handleSort = (op, vo,  id2)=>{
            Confirm.confirm(op,`确认${op} [${vo[field]}] 吗?`,()=>{
                action.sort(vo.id, id2);
            })
        }
        return {title: '排序',dataIndex: 'id',width: 120,render(id, vo){

            return  <div>

                <a disabled={!vo._preId} onClick={handleUp.bind(this,vo)}><ArrowUpOutlined className="mr-20" /></a>
                <a disabled={!vo._nextId} onClick={handleDown.bind(this,vo)}><ArrowDownOutlined  /></a>
            </div>
            }
        }
    },


    renderAmount(v){
        v = v||0;
        return (v).toFixed(2);
    },


    renderRechargeOrderStatus(v){
        let color = "";
        switch (v) {
            case 1: break;
            case 2: color="success";break;
            case 3: break;
            case 4: color="error";break;
        }
        return <Tag color={color}>{window.ENUMS.RechargeOrderStatus[v]}</Tag>
    },
    renderVipOrderStatus(v){
        let color = "";
        switch (v) {
            case 1: break;
            case 2: color="success";break;
            case 3: break;
            case 4: color="error";break;
        }
        return <Tag color={color}>{window.ENUMS.VipOrderStatus[v]}</Tag>
    },
    renderWashOrderStatus(vo){
        let {status, totalAmount, refundAmount} = vo;
        let v= status;
        let color = "";
        let statusName = window.ENUMS.WashOrderStatus[v];
        switch (v) {
            case 1: break;
            case 2: color="success";break;
            case 3: break;
            case 4: color="error";statusName=refundAmount==totalAmount?"全额退款":"部分退款";break;
        }
        return <Tag color={color}>{statusName}</Tag>
    },
    renderShopStationStatus(v){
        let color = "";
        switch (v) {
            case 1: color="warning";break;
            case 2: color="error";break;
            case 3: color="warning";break;
            case 4: color="success";break;
        }
        return <Tag color={color}>{window.ENUMS.ShopStationStatus[v]}</Tag>
    },

    rendeGeekDeviceStatus(v){
        let color = "";
        switch (v) {
            case -1: break;
            case 1: color="success";break;
        }
        return <Tag color={color}>{window.ENUMS.GeekDeviceStatus[v]}</Tag>
    },
    renderUID(id){
        return id && (id+"").padStart(6,"0");
    }
}