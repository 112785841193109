import React from 'react';
import HOC from './Base';

class _403_ extends React.Component {
    constructor (props) {
        super(props);
    }
    render () {
        return (
            <div>
                <div className="status">403</div>
                <div style={{fontSize: 30}}>您没有权限访问此页面</div>
                <div style={{fontSize: 14}}>请联系系统管理员！<span style={{color: '#f00'}}>{this.props.count}</span> 秒后返回首页...</div>
            </div>
        );
    }
}



export default HOC(_403_, {
    count: 3
});