(function () {
    if (typeof window.CustomEvent === 'function') return false;
    function CustomEvent (event, params) {
        params = params || {bubbles: false, cancelable: false, detail: undefined};
        const evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
    }
    CustomEvent.prototype = window.Event.prototype;
    window.CustomEvent = CustomEvent;
})();

export default {
    on: (domTarget, eventName, eventHandler) => {
        var callback = (e) => {
            eventHandler.call(null, e, e.detail);
        };
        eventName.split(' ').forEach(v => domTarget.addEventListener(v, callback, false));
        return callback;
    },
    off: (domTarget, eventName, eventHandler) => {
        domTarget.removeEventListener(eventName, eventHandler, false);
    },
    trigger: (domTarget, eventName, data) => {
        // const event = window.CustomEvent ?
        //     new CustomEvent(eventName, {detail: data}) :
        //     (() => {
        //         const _event = document.createEvent('CustomEvent');
        //         _event.initCustomEvent(eventName, true, true, data);
        //         return _event;
        //     })();
        const event = new CustomEvent(eventName, {detail: data});
        domTarget.dispatchEvent(event);
    },
    makeArray: (arrayLike) => {
        return Array.prototype.slice.call(arrayLike); // Array.from(arrayLike);
    }
};
