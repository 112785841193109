import React from "react";
import {Message} from "@biz/Components";

export default {
    /**
     * 枚举类型的展示
     * @param v
     * @param enums 枚举数据，如果是数组，按照boolean值对应
     * @param type 枚举类型，参考多语言文件下的Enum,优先
     * @returns {*}
     */
    dealEnumValue(v, type, enums){
        if(type){
            enums = window.ENUMS[type];
        }
        if(Array.isArray(enums)){
            return v ? enums[0] : enums[1];
        }else{
            return enums[v] || v;
        }
    },

    buildMap(list, keyField, valueField){
        let map = {};
        list.forEach(o=>{
            let k = o[keyField]+"";
            let v = o[valueField];
            map[k] = v;
        })
        return map;
    },

    // 复制
    copy (text) {
        if (window.clipboardData) {
            window.clipboardData.setData("Text", text);
        } else {
            var textField = document.createElement('textarea')
            textField.innerText = text
            document.body.appendChild(textField)
            textField.select()
            document.execCommand('copy')
            textField.remove()
        }

        Message.success("复制成功");
    }
}