import jq from "./jq-alike";

export default {
    // 登录成功后
    loginSuccess(user) {
        this.initUserInfo(user);
        jq.trigger(window, 'login-success', window.user);
    },
    initUserInfo(user){
        window.user = user;
        window.scope = user.scope;
        window.isRoot = user.scope == "ROOT";
        window.isShop = user.scope == "SHOP";
        localStorage.setItem('user', JSON.stringify(user));
    }
}